import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 스테이지파이브, 카카오엔터프라이즈와 통신 업계 최초 AI개통시스템 구축",
  "| 카카오톡 기반 핀다이렉트 챗봇 내 카카오엔터프라이즈 AI엔진 KAKAO i 적용",
  "| 개통 시 고객이 겪던 2대 어려움 해결… 사전동의 자동화, 개통신청서 수정 자동화",
  "| 업계의 고질적 문제였던 CS 편의성 획기적 개선 기대",
]

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/33/33_img1.png" style={{ maxWidth: "300px", margin: "auto" }} alt="Figure2" />
      </div>
      <div className="desc">&lt;스테이지파이브가 운영 중인 통신플랫폼 ‘핀다이렉트’ 로고&gt;</div>
    </div>
    <p>
      <br />
      ㅣ 스테이지파이브(대표 서상원)은 운영 중인 통신 플랫폼 핀다이렉트(www.pindirect.com) 내 알뜰폰 개통 시 AI 개통 시스템을
      가동한다고 14일 밝혔다. 해당 시스템은 알뜰폰 업계 뿐 아니라 통신 업계 최초로 개발되었으며, 카카오엔터프라이즈의 카카오 i
      엔진을 이용한다.
      <br />
      <br />
      ㅣ스테이지파이브 관계자는 “핀다이렉트를 통해 알뜰폰 요금제에 가입하는 고객들의 전체 상담 건 중 절반 이상인 70%가 개통 관련
      불편”이라며, AI 개통 시스템을 통해 이를 상당 부분 해소할 수 있을 것”이라고 밝혔다. 스테이지파이브에 따르면 개통 관련 불편 건
      중 가장 많이 접수된 상담은 △번호 이동 사전동의(55%)였으며 △개통 신청서 수정(26%)이 뒤를 이었다.
      <br />
      <br />
      ㅣ스테이지파이브는 이를 해소할 수 있는 방안으로 AI라는 기술 혁신 카드를 꺼냈다. 기존 카카오톡 기반으로 운영 중이었던
      핀다이렉트 챗봇 내 AI 개통 시스템을 적용하여 강화하는 방식이다.
      {/* <번호이동 개통 프로세스 비교> */}
      <br />
      <br />
      <div className="descImage">
        <div className="img">
          <img src="/images/press/33/33_img2.png" alt="Figure2" />
        </div>
        <div className="desc">&lt;번호이동 개통 프로세스 비교&gt;</div>
      </div>
      <br />
      <br />
      ㅣ스테이지파이브가 도입한 AI 개통 시스템을 통하면 고객이 직접 복잡한 사전동의 작업을 해야 하거나, 개통 신청서 오기입 시
      고객이 신청서 내 화면에서 일일이 수정해야 했던 번거로움을 줄일 수 있다. 핀다이렉트에서는 카카오 기반 챗봇이 제공하는
      가이드를 따라가다 보면 어려움 없이 개통이 진행된다. 이는 기존보다 편의성 측면에서 매우 강화된 것이 특장점이다. 특히,
      △카카오톡에서 바로 개통할 수 있다는 점과 △고객이 대기해야 하는 불편함 없이 자동화된 개통 프로세스를 경험할 수 있다는 점에서
      현재 타 통신사들이 도입한 셀프 개통 시스템 대비 개선된 모습이다. AI 개통 시스템을 통하면 그간 알똘폰 업계의 고질적 문제로
      대두되었던 CS 편의성도 획기적으로 증진할 수 있게 된다.
      <br />
      <br />
      ㅣ스테이지파이브는 해당 시스템 도입 후 안정적으로 운영함과 동시에 지속적으로 고도화 할 예정이다. 동사는 20년 9월에도
      규제샌드박스 사업을 통해 업계 최초로 통신 가입 시 본인인증 수단으로 간편인증을 도입하는 등 그간 고착되어 있었던 통신 서비스
      분야 고객 경험의 본질적인 혁신에 도전하고 있다.
      <br />
      <br />
      ㅣ 한편, 서상원 대표가 2015년 창업한 스테이지파이브는 다양한 세그먼트를 타겟으로 한 통신 디바이스를 기획 및 제작하고,
      브랜디드 통신 플랫폼인 ‘핀다이렉트’를 통해 E·USIM 요금제를 선보이는 등 통신 내 여러 사업분야에서 두각을 나타내고 있다.
      <br />
    </p>
  </div>
)

const press27 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브"
      writer="커뮤니케이션실 pr@stagefive.com"
      date="2023년 3월 14일 조간 즉시배포 가능"
      pageInfo="(사진자료 메일 첨부)"
      title="내 폰 시세조회, 해외데이터로밍 이용, 내 요금 관리까지 앱 하나로… 올인원 통신앱 ‘핀다이렉트’ 출시"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press27
